<template>
      <div class="inventoryLabResultEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'inventoryLabResult', params: {} }">
          Inventory Lab Result
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'inventoryLabResultEdit', params:{id: id} }">
            Edit Inventory Lab Result
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Inventory Lab Result</v-card-title>
          <v-card-text>
            <inventoryLabResult-form :id="id"></inventoryLabResult-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import InventoryLabResultForm from './InventoryLabResultForm.vue';

    export default {
      name: 'inventoryLabResult',
      data () {
        return {
          id: null
        }
      },
      components: {
        InventoryLabResultForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    